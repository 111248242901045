import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="copyright">
      <p className="p-text">&copy; 2023 Aidar Shaidullin</p>
    </div>
  );
};

export default Footer;
