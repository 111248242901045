import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a href="mailto:aydar013@gmail.com" target="_blank" rel="noreferrer">
          <BiLogoGmail />
        </a>

        <p className="p-text">Send me Email</p>
      </div>
      <div>
        <a href="https://github.com/aydar013" target="_blank" rel="noreferrer">
          <FaGithub />
        </a>

        <p className="p-text">See my GitHub</p>
      </div>
      <div>
        <a
          href="https://www.linkedin.com/in/aidar-shaidullin-424010279/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn />
        </a>

        <p className="p-text">See my LinkedIn</p>
      </div>
      <div>
        <a
          href="https://www.facebook.com/profile.php?id=100012364505077"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
        <p className="p-text">See my Facebook</p>
      </div>
      <div>
        <a
          href="https://www.instagram.com/aydar013/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
        <p className="p-text">See my Instagram</p>
      </div>
    </div>
  );
};

export default SocialMedia;
