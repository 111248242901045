import React, { useState } from "react";
import { images } from "../../constants";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { TbCloudDownload } from "react-icons/tb";
import { motion } from "framer-motion";
import "./NavBar.scss";
import SocialMedia from "../SocialMedia";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.aidarLogo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {["home", "about", "work", "skills", "contact"].map((item) => (
          <li key={`link-${item}`} className="app__flex p-text">
            <div />
            <a href={`#${item}`} onClick={() => setToggle(false)}>
              {item}
            </a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-cv">
        <a
          href="Aidar Shaidullin resume.docx"
          download="Aidar Shaidullin resume.docx"
          className="app__navbar-cv-btn"
        >
          <p>Download resume </p>
          <TbCloudDownload className="icon" />
        </a>
      </div>
      <div className="app__navbar-menu">
        <HiMenuAlt4
          onClick={() => {
            setToggle(true);
          }}
        />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeInOut" }}
          >
            <ul className="app__navbar-links">
              <HiX onClick={() => setToggle(false)} />
              {["home", "about", "work", "skills", "contact"].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <div className="app__menu-cv">
              <a
                href="Aidar Shaidullin resume.docx"
                download="Aidar Shaidullin resume.docx"
                className="app__menu-cv-btn"
              >
                <p>Download resume</p>
                <TbCloudDownload className="app__menu-icon" />
              </a>
            </div>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
