import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Contact.scss";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_g8dhkaq",
      "template_8qon6va",
      form.current,
      "dvho7LmdO8eSWFtlK"
    );
    e.target.reset();
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="app__contact-links">
        <div>
          <a href="mailto:aydar013@gmail.com" target="_blank" rel="noreferrer">
            <HiOutlineMailOpen />
          </a>
        </div>
        <div>
          <a
            href="https://github.com/aydar013"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/aidar-shaidullin-424010279/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/profile.php?id=100012364505077"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/aydar013/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
      <h2 className="head-text">Take a coffee & chat with me</h2>

      <div className="app__contact-cards">
        <div className="app__contact-card ">
          <img src={images.mail} alt="email" />
          <a href="mailto:aydar013@gmail.com" className="p-text">
            aydar013@gmail.com
          </a>
        </div>
        <div className="app__contact-card">
          <img src={images.phone} alt="phone" />
          <a href="tel:+1 (419) 357-7635" className="p-text">
            +1 (419) 357-7635
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <form
          className="app__contact-form app__flex"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="name"
              value={username}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button
            type="submit"
            className="p-text"
            name="submit"
            onClick={handleSubmit}
          >
            {!loading ? "Send Message" : "Sending..."}
          </button>
        </form>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch!</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Contact, "app__contact"),
  "contact",
  "app__primarybg"
);
